<template>
  <b-row class="tableHead py-1 mx-0">
    <b-col
      v-for="(item , index) in items"
      :key="index"
      class="d-flex justify-content-between align-items-center"
    >
      <span class="text-capitalize">
        {{$t(item.title)}}
      </span>
      <div
        class="align-items-center d-flex flex-column justify-content-center"
        v-if="item.sortable"
      >
        <b-button
          @click="sort(item.slug , 'asc')"
          variant="flat-secondary"
          class="btn-icon p-0 sortBtn"
        >
          <feather-icon
            icon="ChevronUpIcon"
         
            :stroke-width="sortBy == item.slug && sortDir == 'asc' ? 6 : 3"
            :size="sortBy == item.slug && sortDir == 'asc' ? '14' : '12'"
          />
        </b-button>
        <b-button
          @click="sort(item.slug , 'desc')"
          variant="flat-secondary"
          class="btn-icon p-0 sortBtn"
        >
          <feather-icon
            icon="ChevronDownIcon"
            :stroke-width="sortBy == item.slug && sortDir == 'desc' ? 6 : 3"
             :size="sortBy == item.slug && sortDir == 'desc' ? '14' : '12'"
            
          />
        </b-button>

      </div>
    </b-col>

  </b-row>

</template>

<script>
  import { BRow, BCol, BButton } from "bootstrap-vue";

  export default {
    props: ["items"],
    components: {
      BRow,
      BCol,
      BButton,
    },
    data() {
      return {
        sortBy: "",
        sortDir: "",
      };
    },

    methods: {
      sort(sortBy, sortDir) {
        if (this.sortBy == sortBy && this.sortDir == sortDir) {
          this.clear();
        } else {
          this.sortBy = sortBy;
          this.sortDir = sortDir;
        }

        this.$emit("sort", { sortBy: this.sortBy, sortDir: this.sortDir });
      },
      clear() {
        this.sortBy = "";
        this.sortDir = "";
      },
    },
  };
</script>



<style lang="scss">
  .tableHead {
    background-color: #f3f2f7;
    font-weight: bold;
    text-transform: uppercase;
  }
  .sortBtn{
     &:focus{
      background-color: transparent !important;
    }
  }
</style>